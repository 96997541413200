<template>
  <DescriptorCard
    class_name="Order"
    title="Orders"
    v-bind="Object.assign({}, $attrs)"
    v-on="$listeners"
    :_modal_component="_modal_component"
    :_predefined_filters="predefined_filters"
    :init_params="init_params"
  />
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

import OrderModal from './OrderModal';

export default {
  components: {
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
  },
  props: {},
  data: () => {
    return {};
  },
  computed: {
    _modal_component() {
      return OrderModal;
    },
    init_params() {
      let init_params = {};

      let user_salesperson = _.first(this.$auth.user[this.$d.getRelationAliasByNames('Salesperson', 'WithUser')]);

      init_params['desired_delivery_date'] = this.$d.momentFormatDate(this.$d.momentSynced().add(1, 'day'));
      init_params[this.$d.getRelationAliasByNames('Order', 'BySalesperson')] = user_salesperson ? [user_salesperson] : null;

      return init_params;
    },
    predefined_filters() {
      return [
        {
          filter_name: 'Is Over Delivered',
          filter_description: 'Orders that Delivered more than Ordered',
          filter_params: {
            filters: [
              {
                filter_type: 'expression',
                operator: 'or',
                filters: [
                  {
                    filter_type: 'property',
                    existence: 'present',
                    property_key: 'extra_quantity',
                    min: 1,
                  },
                ],
              },
              /* {
                filter_type: 'property',
                existence: 'present',
                property_key: 'is_confirmed',
                equal: false,
              }, */
            ],
          },
          frontend: {
            // variant: 'success',
            // variant: 'danger',
            variant: 'warning',
          },
        },
      ];
    },
  },
  created() {
    this._ = _;
  },
  methods: {},
};
</script>
