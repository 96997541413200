<template>
  <Modal
    v-bind="Object.assign({}, $attrs)"
    v-on="$listeners"
    ref="childRef"
    :fixed_params="fixed_params"
    class_name="Order"
    :_read_params="_read_params"
  >
    <template v-slot:modal_view="modal_view_props">
      <!-- {{ modal_view_props.mode }} -->
      <!-- {{modal_view_props.loading}} -->
      <!-- {{ modal_view_props.params }} -->
      <b-tabs class="order_modal" card pills>
        <b-tab active title="Main" lazy>
          <!-- <b-form-group class="form_group" label="Desired Delivery Date" :label-cols="3" :horizontal="true">
            <FormInput propertyType="date" :extParam.sync="modal_view_props.params['desired_delivery_date']" :disabled="false" :show_label="false"></FormInput>
          </b-form-group>-->
          <b-card header>
            <PropertiesView
              :properties="$d.getProperties('Order')"
              :select_property_keys="['desired_delivery_date', 'remarks']"
              :params.sync="modal_view_props.params"
              :disabled="modal_view_props.loading"
            ></PropertiesView>
            <b-form-group
              class="form_group"
              label="Created By User"
              :label-cols="3"
              :horizontal="true"
            >
              <Dropdown
                :relationship="$d.getRelationship('Order', 'CreatedByUser')"
                :params.sync="modal_view_props.params"
                :dropdown_relationships="$d.filterDropdownRelationships(modal_view_props.class_relationships)"
                disabled
              ></Dropdown>
                <!-- :disabled="!$d.hasRole({ from_class_name: 'Administrator' }) || modal_view_props.loading" -->
            </b-form-group>
            <b-form-group
              class="form_group"
              label="By Salesperson"
              :label-cols="3"
              :horizontal="true"
            >
              <Dropdown
                :relationship="$d.getRelationship('Order', 'BySalesperson')"
                :params.sync="modal_view_props.params"
                :dropdown_relationships="$d.filterDropdownRelationships(modal_view_props.class_relationships)"
                :disabled="!$d.hasRole({ from_class_name: 'Administrator' }) || modal_view_props.loading"
              ></Dropdown>
            </b-form-group>
            <b-form-group
              class="form_group"
              label="Bil. Acc. Managed By"
              :label-cols="3"
              :horizontal="true"
            >
              <Dropdown
                :relationship="$d.getRelationship('Order', 'ManagedBy')"
                :params.sync="modal_view_props.params"
                :dropdown_relationships="$d.filterDropdownRelationships(modal_view_props.class_relationships)"
                :disabled="true"
              ></Dropdown>
            </b-form-group>
            <b-form-group
              class="form_group"
              label="Payment Terms"
              :label-cols="3"
              :horizontal="true"
            >
              <Dropdown
                :relationship="$d.getRelationship('Order', 'UsingPaymentTerms')"
                :params.sync="modal_view_props.params"
                :dropdown_relationships="$d.filterDropdownRelationships(modal_view_props.class_relationships)"
                :disabled="modal_view_props.loading"
              ></Dropdown>
            </b-form-group>
          </b-card>
          <b-card header="Bill To BillingAccount">
            <RelView
              :relationship="$d.getRelationship('Order', 'BillTo')"
              :params.sync="modal_view_props.params"
              :fixed_params="fixed_params"
              :disabled="modal_view_props.loading"
            ></RelView>
          </b-card>
          <!-- TODO: Use EditableCard to get instead of defining in "_read_params" separately -->
          <!-- <EditableCard
            title="Bill To BillingAccount"
            :relationship="$d.getRelationship('Order', 'BillTo')"
            :params.sync="modal_view_props.params"
            :fixed_params="fixed_params"
            :relationship_index_params="{
              with_virtual_properties: [],
              with_relationships: [],
            }"
          /> -->
          <b-card header="Under Account">
            <RelView
              :relationship="$d.getRelationship('Order', 'UnderAccount')"
              :params.sync="modal_view_props.params"
              :fixed_params="fixed_params"
              :disabled="modal_view_props.loading"
            ></RelView>
          </b-card>
          <!-- TODO: Use EditableCard to get instead of defining in "_read_params" separately -->
          <!-- <EditableCard
            title="Under Account"
            :relationship="$d.getRelationship('Order', 'UnderAccount')"
            :params.sync="modal_view_props.params"
            :fixed_params="fixed_params"
            :relationship_index_params="{
              with_virtual_properties: ['account_number', 'company_name', 'billing_account_id', 'is_main_account', 'current_account_manager'],
              with_relationships: [],
            }"
          /> -->
          <b-card header="Deliver To Address">
            <RelView
              :relationship="$d.getRelationship('Order', 'DeliverTo')"
              :params.sync="modal_view_props.params"
              :fixed_params="fixed_params"
              :disabled="modal_view_props.loading"
            ></RelView>
          </b-card>
        </b-tab>
        <b-tab title="Contacts" lazy>
          <b-card header="Contact Persons For Delivery">
            <RelView
              :relationship="$d.getRelationship('Order', 'WithDeliveryContacts')"
              :params.sync="modal_view_props.params"
              :fixed_params="fixed_params"
              :disabled="modal_view_props.loading"
            ></RelView>
          </b-card>
          <b-card header="Contact Persons For Invoice">
            <RelView
              :relationship="$d.getRelationship('Order', 'InvoiceTo')"
              :params.sync="modal_view_props.params"
              :fixed_params="fixed_params"
              :disabled="modal_view_props.loading"
            ></RelView>
          </b-card>
        </b-tab>
        <b-tab title="Items" lazy>
          <b-card header="Products">
            <RelView
              :relationship="$d.getRelationship('Order', 'IncludeProducts')"
              :params.sync="modal_view_props.params"
              :fixed_params="fixed_params"
              :disabled="modal_view_props.loading"
            ></RelView>
          </b-card>
          <b-card header="Packages">
            <RelView
              :relationship="$d.getRelationship('Order', 'IncludeOrderPackages')"
              :params.sync="modal_view_props.params"
              :fixed_params="fixed_params"
              :_exclude_fields="[{ property: { property_key: 'order_id' } }]"
              :disabled="modal_view_props.loading"
            >
              <template v-slot:Order_IncludeOrderPackages="{ relationship, params, disabled }">
                <ClassPackageDetails
                  :params.sync="params"
                  :disabled="disabled"
                  :config="{ main_class_name: 'OrderPackage', main_rel_name: 'IncludeOrderPackageGroups', group_class_name: 'OrderPackageGroup' }"
                />
              </template>
            </RelView>
          </b-card>
          <b-card header="Claim Vouchers">
            <RelView
              :relationship="$d.getRelationship('Order', 'ClaimVouchers')"
              :params.sync="modal_view_props.params"
              :fixed_params="fixed_params"
              :disabled="modal_view_props.loading"
            ></RelView>
          </b-card>
        </b-tab>
        <b-tab title="Rentals" lazy>
          <b-card header="Rentals">
            <RelView
              :relationship="$d.getRelationship('Order', 'IncludeOrderRentables')"
              :params.sync="modal_view_props.params"
              :fixed_params="fixed_params"
              :disabled="modal_view_props.loading"
            ></RelView>
          </b-card>
          <hr />
        </b-tab>
        <b-tab title="Logistics" lazy>
          <b-card header="From Warehouse">
            <RelView
              :relationship="$d.getRelationship('Order', 'FromWarehouse')"
              :params.sync="modal_view_props.params"
              :fixed_params="fixed_params"
              :disabled="modal_view_props.loading"
            ></RelView>
          </b-card>
          <b-card header="Service Route">
            <RelView
              :relationship="$d.getRelationship('Order', 'UnderServiceRoute')"
              :params.sync="modal_view_props.params"
              :fixed_params="fixed_params"
              :disabled="modal_view_props.loading"
            ></RelView>
          </b-card>
          <b-card header="For Fleet">
            <RelView
              :relationship="$d.getRelationship('Order', 'ForFleet')"
              :params.sync="modal_view_props.params"
              :fixed_params="fixed_params"
              :disabled="modal_view_props.loading"
            ></RelView>
          </b-card>
        </b-tab>
        <b-tab title="Extras" lazy>
          <b-card header="PO Numbers">
            <RelView
              :relationship="$d.getRelationship('Order', 'ForPurchaseOrderNumbers')"
              :params.sync="modal_view_props.params"
              :fixed_params="fixed_params"
              :disabled="modal_view_props.loading"
            ></RelView>
          </b-card>
          <b-card header="For ReplenishmentOrderDate">
            <div
              class="alert alert-primary"
              role="alert"
            >Select a ReplenishmentOrderDate to Override its Order</div>
            <RelView
              :relationship="$d.getRelationship('Order', 'ForReplenishmentOrderDate')"
              :params.sync="modal_view_props.params"
              :fixed_params="fixed_params"
              :disabled="modal_view_props.loading"
            ></RelView>
          </b-card>
        </b-tab>
      </b-tabs>
    </template>
  </Modal>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

import Modal from '@/descriptor/coreui/Modal'; //cannot use dynamic import or else this.$refs will be undefined

export default {
  components: {
    Modal,
    Dropdown: () => import('@/descriptor/coreui/components/modal/Dropdown'),
    RelView: () => import('@/descriptor/coreui/components/modal/RelView'),
    EditableCard: () => import('@/descriptor/coreui/EditableCard'),
    FormInput: () => import('@/descriptor/coreui/components/modal/FormInput'),
    PropertiesView: () => import('@/descriptor/coreui/components/modal/PropertiesView'),
    ClassPackageDetails: () => import('@/views/descriptor/classes/Class/Package/ClassPackageDetails'),
  },
  props: {
    fixed_params: {},
    _read_params: {
      type: Object,
      default: () => {
        return {
          with_childs: false,
          // with_childs: {
          //   with_virtual_properties: false,
          //   index_withs: false,
          // },

          index_withs: false,
          with_virtual_properties: [],
          with_relationships: [
            {
              from_class_name: 'Order',
              relationship_name: 'CreatedByUser',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'BySalesperson',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'ManagedBy',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'UsingPaymentTerms',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'BillTo',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'UnderAccount',
              with_virtual_properties: ['account_number', 'company_name', 'billing_account_id', 'is_main_account', 'current_account_manager'],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'DeliverTo',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'WithDeliveryContacts',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'InvoiceTo',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'IncludeProducts',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'IncludeOrderPackages',
              with_childs: true, //DANGEROUS
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'ClaimVouchers',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'IncludeOrderRentables',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'FromWarehouse',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'ForFleet',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'UnderServiceRoute',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'ForPurchaseOrderNumbers',
              with_virtual_properties: [],
              with_relationships: [],
            },
            {
              from_class_name: 'Order',
              relationship_name: 'ForReplenishmentOrderDate',
              with_virtual_properties: [],
              with_relationships: [],
            },
            
          ],
        }
      }
    },
  },
  data: () => {
    return {};
  },
  computed: {},
  created() {
    this._ = _;
  },
  mounted() {
    Object.keys(this.$refs['childRef'].$options.methods).forEach(method_name => {
      //IMPORTANT NOTE: the vue ref may be undefined initially, make sure this wrapper component is not imported dynamically, no v-if https://stackoverflow.com/a/63233540/3553367
      //TODO: use mixin?
      if (!this.$options.methods[method_name] && !this[method_name]) {
        console.warn('automatically inherit child component method:', method_name);
        this[method_name] = (...args) => {
          console.warn('automatically called child component method:', method_name);
          if (args && args.length) {
            console.warn('arguments:', args);
          }
          this.$refs['childRef'][method_name](...args);
        };
      }
    });
    console.log(this.$options.methods);
  },
};
</script>

<style scoped>
.form_group >>> .col-form-label {
  font-weight: bold;
}

.order_modal >>> .card-header {
  font-weight: bold;
}
</style>
